import * as Sentry from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { Suspense } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import AuthProvider from './components/AuthProvider/AuthProvider'
import Spinner from './components/Spinner'
import './i18n'
import routes from './routes'
import './sentry'
import queryClient from './utils/queryClient'

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter(routes)

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Suspense fallback={<Spinner isFull />}>
          <RouterProvider router={router} />
        </Suspense>
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default App
