import classnames from 'classnames'

import FormattedNumber from '../FormattedNumber/FormattedNumber'

interface DiffProps {
  value: number
  isCurrency?: boolean
  currency?: string
  decimals?: number
}

const Diff = ({ value, isCurrency, currency, decimals }: DiffProps) => {
  const isPositive = value && value > 0

  return !value ? null : (
    <FormattedNumber
      className={classnames('text-base ml-1', {
        'text-green-500': isPositive,
        'text-red-500': !isPositive,
      })}
      value={value}
      isCurrency={isCurrency}
      currency={currency}
      decimals={decimals}
    />
  )
}

export default Diff
