import { useQuery } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { GridCard } from '../components/Card/Card'
import { Header, Page, Section } from '../components/Page/Page'
import PaginationNav from '../components/PaginationNav/PaginationNav'
import Table from '../components/Table/Table'
import FormattedNumber from '../components/core/FormattedNumber/FormattedNumber'
import { getInvoices } from '../loaders/vendors'
import { VendorInvoice } from '../models'
import { formatDate } from '../utils/format'

const PAGE_SIZE = 20

interface InvoiceListProps {
  invoices: VendorInvoice[]
  onSelected: (invoice: VendorInvoice) => void
}

const InvoiceList = ({ invoices, onSelected }: InvoiceListProps) => {
  const { t } = useTranslation(['dashboard'])

  return (
    <Table
      headerCells={[
        t('invoices.table.vendor'),
        t('invoices.table.number'),
        t('invoices.table.date'),
        t('invoices.table.due'),
        t('invoices.table.amount'),
      ]}
      rows={invoices.map((invoice) => ({
        data: invoice,
        cells: [
          invoice.vendor?.name,
          invoice.invoiceNumber ? `#${invoice.invoiceNumber}` : '-',
          formatDate(invoice.purchaseDate),
          formatDate(invoice.dueDate),
          <FormattedNumber
            key="amount"
            value={invoice.amount}
            isCurrency={true}
          />,
        ],
      }))}
      onRowClick={(invoice) => onSelected(invoice)}
    />
  )
}

const Invoices = () => {
  const { t } = useTranslation(['dashboard'])
  const navigate = useNavigate()
  const [page, setPage] = useState(1)

  const { data } = useQuery({
    queryKey: ['invoices', page],
    queryFn: () => getInvoices({ page, pageSize: PAGE_SIZE }),
  })

  const invoices = useMemo(() => data?.data ?? [], [data])
  const pagination = useMemo(() => data?.pagination, [data])

  const onSelected = (invoice: VendorInvoice) => {
    navigate(`/vendors/invoices/${invoice.id}`)
  }

  return (
    <Page>
      <Header subtitle={t('invoices.subtitle') ?? ''}>
        {t('invoices.title')}
      </Header>
      <Section>
        <GridCard>
          <InvoiceList invoices={invoices} onSelected={onSelected} />
          {pagination && pagination.pageCount > 1 && (
            <PaginationNav
              pagination={pagination}
              onPrevious={() => setPage(pagination?.previousPage ?? 1)}
              onNext={() => setPage(pagination?.nextPage ?? 1)}
            />
          )}
        </GridCard>
      </Section>
    </Page>
  )
}

export default Invoices
