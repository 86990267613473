import { ComponentPropsWithoutRef } from 'react'
import { useTranslation } from 'react-i18next'

import FormattedNumber from '../../components/core/FormattedNumber/FormattedNumber'
import { VendorInvoice, VendorInvoiceLine } from '../../models'
import { formatNumber } from '../../utils/format'

interface LineRowProps extends ComponentPropsWithoutRef<'tr'> {
  invoice: VendorInvoice
  line: VendorInvoiceLine
}

const LineRow = ({ invoice, line, ...props }: LineRowProps) => {
  const { t } = useTranslation(['dashboard'])

  let quantity = formatNumber(line.quantity)
  if (line.unit) {
    quantity = `${quantity} ${t(`invoice.unit.${line.unit}`)}`
  }

  return (
    <tr className="border-b border-gray-100" {...props}>
      <td className="max-w-0 px-0 py-5 align-top">
        <div className="truncate font-medium text-gray-900">
          {line.description}
        </div>
      </td>
      <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
        {quantity}
      </td>
      <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
        <FormattedNumber
          value={line.unitPrice}
          isCurrency={true}
          currency={invoice.currency}
        />
      </td>
      <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
        <FormattedNumber
          value={line.amount}
          isCurrency={true}
          currency={invoice.currency}
        />
      </td>
    </tr>
  )
}

interface LinesProps {
  invoice: VendorInvoice
}

const Lines = ({ invoice }: LinesProps) => {
  const { t } = useTranslation(['dashboard'])

  return (
    <table className="w-full whitespace-nowrap text-left text-sm/6">
      <colgroup>
        <col className="w-full" />
        <col />
        <col />
        <col />
      </colgroup>
      <thead className="border-b border-gray-200 text-gray-900">
        <tr>
          <th scope="col" className="px-0 py-3 font-semibold">
            {t('invoice.description')}
          </th>
          <th
            scope="col"
            className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
          >
            {t('invoice.quantity')}
          </th>
          <th
            scope="col"
            className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
          >
            {t('invoice.unitPrice')}
          </th>
          <th scope="col" className="py-3 pl-8 pr-0 text-right font-semibold">
            {t('invoice.amount')}
          </th>
        </tr>
      </thead>
      <tbody>
        {invoice.lines.map((line) => (
          <LineRow key={line.id} invoice={invoice} line={line} />
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th
            scope="row"
            className="px-0 pb-0 pt-6 font-normal text-gray-700 sm:hidden"
          >
            {t('invoice.subtotal')}
          </th>
          <th
            scope="row"
            colSpan={3}
            className="hidden px-0 pb-0 pt-6 text-right font-normal text-gray-700 sm:table-cell"
          >
            {t('invoice.subtotal')}
          </th>
          <td className="pb-0 pl-8 pr-0 pt-6 text-right tabular-nums text-gray-900">
            <FormattedNumber
              value={invoice.amount}
              isCurrency={true}
              currency={invoice.currency}
            />
          </td>
        </tr>
        <tr>
          <th scope="row" className="pt-4 font-normal text-gray-700 sm:hidden">
            {t('invoice.vat')}
          </th>
          <th
            scope="row"
            colSpan={3}
            className="hidden pt-4 text-right font-normal text-gray-700 sm:table-cell"
          >
            {t('invoice.vat')}
          </th>
          <td className="pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-900">
            <FormattedNumber
              value={invoice.vatAmount}
              isCurrency={true}
              currency={invoice.currency}
            />
          </td>
        </tr>
        <tr>
          <th
            scope="row"
            className="pt-4 font-semibold text-gray-900 sm:hidden"
          >
            {t('invoice.total')}
          </th>
          <th
            scope="row"
            colSpan={3}
            className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
          >
            {t('invoice.total')}
          </th>
          <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900">
            <FormattedNumber
              value={invoice.totalAmount}
              isCurrency={true}
              currency={invoice.currency}
            />
          </td>
        </tr>
      </tfoot>
    </table>
  )
}

export default Lines
