import { useMemo } from 'react'

import useAuth from '../../hooks/useAuth'

export const Avatar = () => {
  const auth = useAuth()

  const initials = useMemo(() => {
    if (auth && auth.user) {
      const { firstName, lastName } = auth.user
      return `${firstName[0]}${lastName[0]}`.toUpperCase()
    }
  }, [auth])

  return (
    <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
      <span className="text-sm font-medium leading-none text-white">
        {initials}
      </span>
    </span>
  )
}
