import logo from '../assets/img/logo_dark.svg'

interface LogoProps {
  altText?: string
  className?: string
}

const Logo = ({ altText = 'Logo', className }: LogoProps) => {
  return <img className={className} src={logo} alt={altText} />
}

export default Logo
