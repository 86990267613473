import { AgGridReact } from '@ag-grid-community/react'
import { LicenseManager } from '@ag-grid-enterprise/core'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import PropTypes from 'prop-types'
import { memo } from 'react'

import '../../assets/css/ag-grid.css'
import Spinner from '../Spinner'

const AG_GRID_LICENSE_KEY = import.meta.env.VITE_AG_GRID_LICENSE_KEY

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY)

export const LoadingCellRenderer = memo(({ loadingMessage }) => (
  <div className="w-full h-full pl-12 flex items-center justify-start">
    <Spinner size="sm" />
    <span>{loadingMessage}</span>
  </div>
))

LoadingCellRenderer.propTypes = {
  loadingMessage: PropTypes.string,
}

LoadingCellRenderer.displayName = 'LoadingCellRenderer'

export default AgGridReact
