import { Category } from '../models'

export const getCategoryComponents = (category: Category) => {
  const path: string[] = []

  if (category.businessOrigin) {
    path.push(category.businessOrigin)
  }

  if (category.type) {
    path.push(category.type)
  }

  if (category.entry) {
    path.push(category.entry)
  }

  if (category.concerning) {
    path.push(category.concerning)
  }

  if (category.specifics) {
    path.push(category.specifics)
  }

  return path
}
