import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import Modal, { ModalOptions } from './Modal'

interface ModalContextType {
  showModal: (content: ReactNode, options?: ModalOptions) => void
  hideModal: () => void
  open: boolean
}

const ModalContext = createContext<ModalContextType>({
  showModal: (content: ReactNode) => {}, // eslint-disable-line @typescript-eslint/no-unused-vars
  hideModal: () => {},
  open: false,
})

ModalContext.displayName = 'ModalContext'

export function useModal() {
  return useContext(ModalContext)
}

export function ModalProvider({ children }: { children: ReactNode }) {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<ModalOptions | null>(null)
  const [content, setContent] = useState<ReactNode | null>(null)

  const showModal = useCallback(
    (content: ReactNode, options?: ModalOptions) => {
      setOptions(options ?? null)
      setContent(content)
      setOpen(true)
    },
    [setOptions, setContent, setOpen]
  )

  const hideModal = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const onClosed = useCallback(() => {
    if (!open) {
      setOptions(null)
      setContent(null)
    }
  }, [open, setOptions, setContent])

  const value = useMemo(
    () => ({ showModal, hideModal, open }),
    [showModal, hideModal, open]
  )

  return (
    <ModalContext.Provider value={value}>
      <Modal
        open={open}
        setOpen={setOpen}
        options={options}
        content={content}
        onClosed={onClosed}
      />
      {children}
    </ModalContext.Provider>
  )
}
