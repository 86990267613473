import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { ComponentPropsWithoutRef } from 'react'

import { Category } from '../../models'
import { getCategoryComponents } from '../../utils/category'
import Button from '../core/Button/Button'

interface CategoryButtonProps extends ComponentPropsWithoutRef<'button'> {
  category: Category
}

const CategoryButton = ({ category, ...props }: CategoryButtonProps) => {
  return (
    <Button variant="soft" size="xs" className="rounded-full" {...props}>
      <div className="flex flex-row items-center gap-1">
        {getCategoryComponents(category).map((path, index) => (
          <div key={index} className="flex flex-row items-center">
            <span>{path}</span>
            {index < getCategoryComponents(category).length - 1 && (
              <ChevronRightIcon
                aria-hidden="true"
                className="size-3 shrink-0 ml-1 text-gray-400"
              />
            )}
          </div>
        ))}
      </div>
    </Button>
  )
}

export default CategoryButton
