import { DocumentIcon } from '@heroicons/react/24/outline'
import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import {
  createDocument,
  getReference,
  getUploadUrl,
  processDocument,
  uploadFile,
} from '../../loaders/documents'
import {
  DocumentKind,
  TenantAgreement,
  VendorAgreement,
  VendorInvoice,
} from '../../models'
import Spinner from '../Spinner'
import Button from '../core/Button/Button'

interface UploadFile {
  file: File
  status: 'pending' | 'uploading' | 'processing' | 'done' | 'error'
  progress: number
  kind?: DocumentKind | null
  kindId?: number | null
  reference?: VendorAgreement | TenantAgreement | VendorInvoice | null
}

const ProgressBar = ({ progress }: { progress: number }) => {
  return (
    <div className="w-full overflow-hidden rounded-full bg-gray-200">
      <div
        style={{ width: `${progress}%` }}
        className="h-2 rounded-full bg-brand-600"
      />
    </div>
  )
}

const ReferenceButton = ({ file }: { file: UploadFile }) => {
  const { t } = useTranslation(['dashboard'])

  if (file.kind === 'vendor_invoice') {
    const invoice = file.reference as VendorInvoice
    return (
      <Button variant="soft" size="sm">
        <NavLink to={`/vendors/invoices/${invoice.id}`}>
          {t('upload.reference.vendor_invoice', {
            invoiceNumber: invoice.invoiceNumber,
            vendor: invoice.vendor?.name,
          })}
        </NavLink>
      </Button>
    )
  } else if (file.kind === 'vendor_agreement') {
    const agreement = file.reference as VendorAgreement
    return (
      <Button variant="soft" size="sm">
        <NavLink to={`/vendors/${agreement.vendor?.id}`}>
          {t('upload.reference.vendor_agreement')}
        </NavLink>
      </Button>
    )
  } else if (file.kind === 'tenant_agreement') {
    const agreement = file.reference as TenantAgreement
    return (
      <Button variant="soft" size="sm">
        <NavLink to={`/tenants/${agreement.tenant?.id}`}>
          {t('upload.reference.tenant_agreement')}
        </NavLink>
      </Button>
    )
  }

  return null
}

interface StatusProps {
  file: UploadFile
}

const Status = ({ file }: StatusProps) => {
  const { t } = useTranslation(['dashboard'])

  if (file.status === 'uploading') {
    return <ProgressBar progress={file.progress} />
  }

  if (file.reference) {
    return <ReferenceButton file={file} />
  }

  return (
    <div className="w-full flex items-center justify-end gap-2 text-gray-500">
      {file.status === 'processing' ? <Spinner size="sm" /> : null}
      {t(`upload.status.${file.status}`)}
    </div>
  )
}

const FileList = ({ files }: { files: UploadFile[] }) => {
  return (
    <div className="w-full mt-4 px-4 align-middle border rounded-lg">
      <table className="min-w-full divide-y divide-gray-300">
        <tbody className="divide-y divide-gray-200">
          {files.map((file, idx) => (
            <tr key={idx}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                {file.file.name}
              </td>
              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0 w-64">
                <Status file={file} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const DocumentUploader = () => {
  const { t } = useTranslation(['dashboard'])
  const [files, setFiles] = useState<UploadFile[]>([])

  // helper to cause side effect and re-render files
  const updateFile = useCallback((effect: () => void) => {
    effect()
    setFiles((prev) => [...prev])
  }, [])

  const onDrop = useCallback((droppedFiles: File[]) => {
    const files: UploadFile[] = droppedFiles.map((file) => ({
      file,
      progress: 0,
      status: 'pending',
    }))
    setFiles((prev) => [...prev, ...files])
  }, [])

  const uploadFiles = useCallback(async () => {
    const uploadDocument = async (file: UploadFile) => {
      try {
        // Get presigned url
        updateFile(() => (file.status = 'uploading'))
        const { url, location } = await getUploadUrl(file.file.name)

        // Upload file to S3
        await uploadFile(url, file.file, {
          onProgress: (progress) =>
            updateFile(() => (file.progress = progress)),
        })

        // Create document
        const { id: documentId } = await createDocument(
          file.file.name,
          location
        )

        // Process document
        updateFile(() => (file.status = 'processing'))
        const { kind, kindId } = await processDocument(documentId)
        updateFile(() => {
          file.kind = kind
          file.kindId = kindId
        })

        if (kind && kindId) {
          const reference = await getReference(kind, kindId)
          updateFile(() => (file.reference = reference))
        }

        updateFile(() => (file.status = 'done'))
      } catch (error) {
        updateFile(() => (file.status = 'error'))
        toast.error(
          t('document.process.notification.error', {
            error: (error as Error).toString(),
          })
        )
      }
    }

    Promise.all(files.map(uploadDocument))
  }, [files, updateFile])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'image/*': ['.png', '.jpg', '.jpeg'],
    },
    maxSize: 10 * 1024 * 1024, // 10MB
    multiple: true,
  })

  return (
    <>
      {files.length ? <FileList files={files} /> : null}
      <div className="mt-4 py-4 px-32 rounded-lg bg-gray-100">
        <div
          {...getRootProps({
            className: 'dropzone my-4 flex justify-center',
          })}
        >
          <div className="text-center">
            <DocumentIcon
              aria-hidden="true"
              className="mx-auto size-12 text-gray-300"
            />
            <div className="mt-4 flex text-sm/6 text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md font-semibold text-brand-700 hover:text-brand-600"
              >
                <span>{t('upload.action')}</span>
                <input {...getInputProps()} />
              </label>
              <p className="pl-1">{t('upload.label')}</p>
            </div>
            <p className="text-xs/5 text-gray-600">{t('upload.limits')}</p>
          </div>
        </div>
      </div>
      <div className="mt-4 text-right">
        <Button onClick={uploadFiles} disabled={files.length === 0}>
          {t('upload.submit')}
        </Button>
      </div>
    </>
  )
}

export default DocumentUploader
