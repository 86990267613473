import { PlusIcon } from '@heroicons/react/24/outline'

const UploadButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      type="button"
      className="rounded-full bg-brand-600 p-2 text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
      onClick={onClick}
    >
      <PlusIcon aria-hidden="true" className="size-8" />
    </button>
  )
}

export default UploadButton
