import axios from 'axios'

import { Document, DocumentKind, DocumentProcessResult } from '../models'
import httpClient from '../utils/httpClient'
import { fromJsonApi } from '../utils/jsonapi'
import { getAgreement as getTenantAgreement } from './tenants'
import { getInvoice, getAgreement as getVendorAgreement } from './vendors'

export const getDownloadUrl = async (documentId: number) => {
  const response = await httpClient.post(`/document/${documentId}/download`)

  return response.data
}

export const getUploadUrl = async (fileName: string) => {
  const response = await httpClient.post('/document/upload', { fileName })

  return response.data
}

export const uploadFile = async (
  url: string,
  file: File,
  {
    onProgress,
  }: {
    onProgress?: (progress: number) => void
  } = {}
) => {
  const response = await axios.put(url, file, {
    onUploadProgress: (event) => {
      if (event.lengthComputable && event.total) {
        const progress = (event.loaded / event.total) * 100
        onProgress?.(progress)
      }
    },
  })

  return response.data
}

export const processDocument = async (documentId: number) => {
  const response = await httpClient.post<DocumentProcessResult>(
    `/document/${documentId}/process`
  )

  return response.data
}

export const getDocument = async (documentId: number) => {
  const response = await httpClient.get(`/document/${documentId}`)

  return fromJsonApi<Document>(response.data)
}

export const createDocument = async (name: string, location: string) => {
  const response = await httpClient.post('/document', { name, location })

  return fromJsonApi<Document>(response.data)
}

export const getReference = async (kind: DocumentKind, kindId: number) => {
  if (kind === 'vendor_agreement') {
    return getVendorAgreement(kindId)
  } else if (kind === 'tenant_agreement') {
    return getTenantAgreement(kindId)
  } else if (kind === 'vendor_invoice') {
    return getInvoice(kindId)
  }

  return null
}
