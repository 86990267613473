import { ComponentPropsWithoutRef } from 'react'

import useNumberOptions from '../../../hooks/useNumberOptions'
import { formatNumber } from '../../../utils/format'

interface FormattedNumberProps extends ComponentPropsWithoutRef<'span'> {
  value?: number
  isCurrency?: boolean
  currency?: string
  defaultValue?: number | string
  className?: string
  decimals?: number
}

const FormattedNumber = ({
  value,
  className,
  isCurrency,
  currency,
  decimals,
  defaultValue = '-',
}: FormattedNumberProps) => {
  const numberOptions = useNumberOptions({ isCurrency, currency })
  return (
    <span className={className}>
      {value
        ? formatNumber(value, { ...numberOptions, decimals })
        : defaultValue}
    </span>
  )
}

export default FormattedNumber
