import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import resources from 'virtual:i18next-loader'

export const SUPPORTED_LOCALES = ['en', 'da']
export const DEFAULT_LOCALE = 'en'
export const DEFAULT_NS = 'translation'
const DEBUG = import.meta.env.DEV

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: DEFAULT_LOCALE,
    supportedLngs: SUPPORTED_LOCALES,
    debug: DEBUG,
    ns: DEFAULT_NS,
    returnNull: false,

    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
