import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  NoSymbolIcon,
} from '@heroicons/react/24/outline'
import { ComponentPropsWithoutRef, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InvoiceAnalysis } from '../../models'
import ProgressSteps from '../ProgressSteps/ProgressSteps'
import Markdown from '../core/Markdown/Markdown'

type RecommendationType = 'accept' | 'reject' | 'investigate'

const recommendationIcon: Record<RecommendationType, React.ReactNode> = {
  accept: <CheckCircleIcon className="h-8 w-auto text-brand-600" />,
  reject: <NoSymbolIcon className="h-8 w-auto text-red-600" />,
  investigate: <ExclamationCircleIcon className="h-8 w-auto text-yellow-400" />,
}

interface RecommendationProps extends ComponentPropsWithoutRef<'div'> {
  type: RecommendationType
}

const Recommendation = ({ type }: RecommendationProps) => {
  const { t } = useTranslation(['dashboard'])

  return (
    <div className="flex flex-col items-center gap-2 px-3 py-3 bg-white text-gray-600 rounded-lg">
      {recommendationIcon[type]}
      <span className="text-sm">{t(`invoice.ai.recommendation.${type}`)}</span>
    </div>
  )
}

interface AnalysisSectionProps extends ComponentPropsWithoutRef<'div'> {
  title: string
  text: string
}

const AnalysisSection = ({ title, text }: AnalysisSectionProps) => {
  return (
    <div className="px-3 py-3 bg-white text-gray-600 rounded-lg ease-in duration-300">
      <div className="font-semibold text-sm">{title}</div>
      <div className="mt-2 text-sm">
        <Markdown>{text}</Markdown>
      </div>
    </div>
  )
}

const STEP_DURATION = 2000

const simulateSteps = async (
  length: number,
  setCurrentStepIndex: (index: number) => void
) => {
  for (let i = 0; i < length; i++) {
    await new Promise((resolve) => setTimeout(resolve, STEP_DURATION))
    setCurrentStepIndex(i)
  }
}

interface AISidebarProps extends ComponentPropsWithoutRef<'div'> {
  steps: string[]
  stepIndex?: number
  isLoading: boolean
  analysis?: InvoiceAnalysis
}

const AISidebar = ({
  steps,
  isLoading,
  stepIndex,
  analysis,
}: AISidebarProps) => {
  const { t } = useTranslation(['dashboard'])
  const [currentStepIndex, setCurrentStepIndex] = useState(stepIndex ?? 0)

  // Simulate steps visually
  useEffect(() => {
    setCurrentStepIndex(stepIndex ?? 0)
    simulateSteps(steps.length, setCurrentStepIndex)
  }, [steps, stepIndex])

  // Track if all steps have been completed
  const stepsCompleted = useMemo(() => {
    return currentStepIndex >= steps.length - 1
  }, [currentStepIndex, steps])

  return (
    <div className="bg-gray-100 w-96 h-full px-4 py-4 rounded-xl animate-fadeIn">
      {!stepsCompleted || isLoading ? (
        <div className="my-12">
          <ProgressSteps steps={steps} currentStepIndex={currentStepIndex} />
        </div>
      ) : (
        <div className="space-y-4 animate-fadeIInTranslateY">
          <Recommendation
            type={analysis?.recommendation as RecommendationType}
          />
          {analysis?.summary && (
            <AnalysisSection
              title={t('invoice.ai.analysis.summary')}
              text={analysis?.summary}
            />
          )}
          {analysis?.correctness && (
            <AnalysisSection
              title={t('invoice.ai.analysis.correctness')}
              text={analysis?.correctness}
            />
          )}
          {analysis?.agreements && (
            <AnalysisSection
              title={t('invoice.ai.analysis.agreements')}
              text={analysis?.agreements}
            />
          )}
          {analysis?.invoices && (
            <AnalysisSection
              title={t('invoice.ai.analysis.invoices')}
              text={analysis?.invoices}
            />
          )}
          {analysis?.payments && (
            <AnalysisSection
              title={t('invoice.ai.analysis.payments')}
              text={analysis?.payments}
            />
          )}
          {analysis?.budgets && (
            <AnalysisSection
              title={t('invoice.ai.analysis.budgets')}
              text={analysis.budgets}
            />
          )}
          {analysis?.accounting && (
            <AnalysisSection
              title={t('invoice.ai.analysis.accounting')}
              text={analysis.accounting}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default AISidebar
