import MarkdownElement, { MarkdownToJSX } from 'markdown-to-jsx'
import { ComponentPropsWithoutRef } from 'react'

const options = {
  overrides: {
    span: {
      component: 'span',
      props: {
        className: 'text-sm',
      },
    },
    strong: {
      component: 'span',
      props: {
        className: 'font-semibold',
      },
    },
  },
} as MarkdownToJSX.Options

const Markdown = ({
  children,
}: ComponentPropsWithoutRef<typeof MarkdownElement>) => {
  return <MarkdownElement options={options}>{children}</MarkdownElement>
}

export default Markdown
