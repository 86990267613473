import { useTranslation } from 'react-i18next'

import Diff from '../core/Diff/Diff'
import FormattedNumber from '../core/FormattedNumber/FormattedNumber'
import { Overview } from './NoiChart'

type OverviewKey = 'actual' | 'budget' | 'underwriting'

const overviewLabels: { id: OverviewKey; label: string; diff?: boolean }[] = [
  { id: 'actual', label: 'overview.overview.stats.actual', diff: true },
  { id: 'budget', label: 'overview.overview.stats.budget' },
  { id: 'underwriting', label: 'overview.overview.stats.underwriting' },
]

interface KPIsProps {
  overview: Overview
}

const KPIs = ({ overview }: KPIsProps) => {
  const { t } = useTranslation(['dashboard'])

  return (
    <div className="flex flex-col justify-evenly h-full space-y-6">
      {overviewLabels.map(({ id, label, diff }) => (
        <div key={id} className="flex flex-col text-right">
          <div className="text-base text-gray-500">{t(label)}</div>
          <div className="text-3xl text-gray-900">
            <FormattedNumber
              className="font-medium"
              value={overview[id as OverviewKey]}
              isCurrency={true}
              decimals={0}
            />
          </div>
          {diff && (
            <div className="text-base">
              <Diff
                value={overview['budgetDiff'] ?? 0}
                isCurrency={true}
                decimals={0}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default KPIs
