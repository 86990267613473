import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'

import useNumberOptions from '../../hooks/useNumberOptions'
import {
  createInvoice,
  deleteInvoice,
  getVendorInvoices,
} from '../../loaders/vendors'
import { Vendor, VendorInvoice } from '../../models'
import { formatDate, formatNumber } from '../../utils/format'
import DocumentList, {
  DocumentCard,
  UploadCard,
} from '../DocumentList/DocumentList'
import Spinner from '../Spinner'

interface InvoiceListProps {
  vendor: Vendor
  count: number
  onChange?: () => void
}

const InvoiceList = ({ vendor, count, onChange }: InvoiceListProps) => {
  const queryClient = useQueryClient()

  const numberOptions = useNumberOptions({
    isCurrency: true,
  })

  const [invoices, setInvoices] = useState<VendorInvoice[]>([])

  const { isLoading } = useQuery({
    queryKey: ['invoices', vendor.id],
    queryFn: () => getVendorInvoices(vendor.id, { pageSize: count }),
    onSuccess: (data) => {
      setInvoices(data.data)
      onChange?.()
    },
  })

  const { mutate: createMutation } = useMutation({
    mutationKey: ['invoices', vendor.id],
    mutationFn: ({ name, location }: { name: string; location: string }) => {
      return createInvoice(vendor.id, name, location)
    },
    onSuccess: (invoice) => {
      setInvoices([...invoices, invoice])
      onChange?.()
    },
  })

  const { mutate: deleteMutation } = useMutation({
    mutationFn: (invoice: VendorInvoice) => deleteInvoice(invoice.id),
    onSuccess: (_, invoice) => {
      const newInvoices = invoices.filter((i) => i.id !== invoice.id)
      setInvoices(newInvoices)
      onChange?.()
    },
  })

  const onUpdate = () => {
    queryClient.invalidateQueries({ queryKey: ['invoices', vendor.id] })
    onChange?.()
  }

  if (isLoading) {
    return (
      <div className="flex justify-center w-full">
        <Spinner size="sm" />
      </div>
    )
  }

  return (
    <DocumentList id="invoices">
      {invoices.map((invoice) => (
        <DocumentCard
          key={`invoice-${invoice.id}`}
          document={invoice.document!}
          leftText={
            invoice.invoiceNumber
              ? `#${invoice.invoiceNumber} (${formatDate(invoice.purchaseDate, {
                  format: 'L',
                })})`
              : '-'
          }
          rightText={formatNumber(invoice.amount, numberOptions) ?? '-'}
          onDelete={() => deleteMutation(invoice)}
          onUpdate={onUpdate}
        />
      ))}
      <UploadCard
        id="invoice-upload"
        onUpload={(name, location) => createMutation({ name, location })}
      />
    </DocumentList>
  )
}

export default InvoiceList
