import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApiCallback } from '../../hooks/api'
import useAuth from '../../hooks/useAuth'
import { updateRecord } from '../../loaders/records'
import { Record } from '../../models'
import CategoryButton from '../CategoryButton/CategoryButton'
import {
  Form,
  FormField,
  FormFieldGrid,
  FormLabel,
  FormSection,
  FormValue,
} from '../core/Form/Form'
import FormattedNumber from '../core/FormattedNumber/FormattedNumber'
import { Switch, SwitchGroup } from '../core/Switch'

interface RecordDetailProps {
  record: Record
  onUpdate: (record: Record) => void
}

export default function RecordDetail({
  record: initialRecord,
  onUpdate,
}: RecordDetailProps) {
  const { t } = useTranslation(['dashboard'])

  const { rights } = useAuth()
  const canEdit = rights.includes('record:write')

  const [record, setRecord] = useState(initialRecord)

  const toggleOneOff = useApiCallback(
    async () => {
      const newOneOff = !record.oneOff
      record.oneOff = newOneOff
      setRecord(record)

      await updateRecord({
        recordId: record.recordId,
        oneOff: newOneOff,
      })

      onUpdate(record)
    },
    record,
    {
      successKey: 'recordDetail.notification.success',
      errorKey: 'recordDetail.notification.error',
    }
  )

  return (
    <Form>
      <FormSection
        title={t('recordDetail.general')}
        subtitle={t('recordDetail.generalDescription')}
      >
        <FormFieldGrid>
          <FormField>
            <FormLabel>{t('recordDetail.amount')}</FormLabel>
            <FormValue>
              <FormattedNumber value={record.amount} isCurrency={true} />
            </FormValue>
          </FormField>

          <FormField>
            <FormLabel>{t('recordDetail.date')}</FormLabel>
            <FormValue>{record.processingDate}</FormValue>
          </FormField>

          <FormField>
            <FormLabel>{t('recordDetail.oneOff')}</FormLabel>
            <FormValue>
              <SwitchGroup as="div">
                <Switch
                  enabled={record.oneOff}
                  disabled={!canEdit}
                  onChange={toggleOneOff}
                ></Switch>
              </SwitchGroup>
            </FormValue>
          </FormField>

          <FormField>
            <FormLabel>{t('recordDetail.building')}</FormLabel>
            <FormValue>{record.building.buildingReference}</FormValue>
          </FormField>

          <FormField>
            <FormLabel>{t('recordDetail.account')}</FormLabel>
            <FormValue>
              {record.account.name} ({record.account.number})
            </FormValue>
          </FormField>

          <FormField>
            <FormLabel>{t('recordDetail.category')}</FormLabel>
            <FormValue>
              <CategoryButton category={record.category} />
            </FormValue>
          </FormField>
        </FormFieldGrid>
      </FormSection>
    </Form>
  )
}
