import {
  ArrowPathIcon,
  DocumentTextIcon,
  SparklesIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  ComponentPropsWithoutRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import AISidebar from '../../components/AISidebar/AISidebar'
import Card from '../../components/Card/Card'
import CategoryButton from '../../components/CategoryButton/CategoryButton'
import Checked from '../../components/Checked'
import { Header, Page, Section } from '../../components/Page/Page'
import Spinner from '../../components/Spinner'
import { Button } from '../../components/core/Button/Button'
import { useDrawer } from '../../components/core/Drawer/DrawerProvider'
import { getDownloadUrl, processDocument } from '../../loaders/documents'
import {
  deleteInvoice as deleteVendorInvoice,
  getInvoice,
  getInvoiceAnalysis,
} from '../../loaders/vendors'
import { InvoiceAnalysis, VendorInvoice } from '../../models'
import { formatDate } from '../../utils/format'
import Lines from './Lines'

interface FieldProps extends ComponentPropsWithoutRef<'div'> {
  label: string
}

const Field = ({ label, children }: FieldProps) => {
  return (
    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt className="text-sm/6 font-medium text-gray-900">{label}</dt>
      <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
        {children}
      </dd>
    </div>
  )
}

interface FieldListProps {
  invoice: VendorInvoice
}

const FieldList = ({ invoice }: FieldListProps) => {
  const { t } = useTranslation(['dashboard'])
  const navigate = useNavigate()
  return (
    <dl className="divide-y divide-gray-100">
      <Field label={t('invoice.purchaseDate')}>
        {formatDate(invoice.purchaseDate)}
      </Field>
      {invoice.dueDate && (
        <Field label={t('invoice.dueDate')}>
          {formatDate(invoice.dueDate)}
        </Field>
      )}
      {invoice.periodStartDate && invoice.periodEndDate && (
        <Field label={t('invoice.period')}>
          {`${formatDate(invoice.periodStartDate)} - ${formatDate(
            invoice.periodEndDate
          )}`}
        </Field>
      )}
      {invoice.utilityMeterId && (
        <Field label={t('invoice.utilityMeter')}>
          {invoice.utilityMeterId}
        </Field>
      )}
      <Field label={t('invoice.currency')}>{invoice.currency}</Field>
      <Field label={t('invoice.vendor')}>
        <Button
          variant="soft"
          size="xs"
          className="rounded-full"
          onClick={() => navigate(`/vendors`)}
        >
          <span>{invoice?.vendor?.name}</span>
        </Button>
      </Field>

      {invoice.category && (
        <Field label={t('invoice.category')}>
          <CategoryButton category={invoice.category} />
        </Field>
      )}
    </dl>
  )
}

const stepLabels: string[] = [
  'invoice.ai.step.correctness',
  'invoice.ai.step.agreements',
  'invoice.ai.step.invoices',
  'invoice.ai.step.payments',
  'invoice.ai.step.budgets',
  'invoice.ai.step.accounting',
  'invoice.ai.step.summary',
]

export const Invoice = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { t } = useTranslation(['dashboard'])
  const { showDrawer } = useDrawer()
  const { invoiceId } = useParams()

  const { data: invoice, isLoading } = useQuery({
    queryKey: ['invoice', invoiceId],
    queryFn: () => getInvoice(Number(invoiceId)),
  })

  const [stepIndex, setStepIndex] = useState(0)
  const [analysis, setAnalysis] = useState<InvoiceAnalysis | undefined>(
    undefined
  )

  const { mutate: analyzeInvoice, isLoading: isAnalyzing } = useMutation({
    mutationFn: (invoiceId: number) => getInvoiceAnalysis(invoiceId),
    onSuccess: setAnalysis,
  })

  const { mutate: deleteInvoice, isLoading: isDeleting } = useMutation({
    mutationFn: (invoiceId: number) => deleteVendorInvoice(invoiceId),
    onSuccess: () => {
      navigate('/vendors/invoices')
      toast.success(t('invoice.delete.notification.success'))
    },
  })

  const { mutate: processInvoice, isLoading: isProcessing } = useMutation({
    mutationFn: (documentId: number) => processDocument(documentId),
    onSuccess: () => {
      // trigger re-render of invoice since its now been processed
      queryClient.invalidateQueries({ queryKey: ['invoice', invoiceId] })
      toast.success(t('invoice.process.notification.success'))
    },
    onError: (error) => {
      toast.error(t('invoice.process.notification.error', { error }))
    },
  })

  const onDownload = useCallback(async () => {
    if (invoice) {
      const { url } = await getDownloadUrl(invoice.document!.id)
      window.open(url, '_blank')
    }
  }, [invoice])

  useEffect(() => {
    if (isAnalyzing || analysis) {
      showDrawer(
        t('invoice.ai.title', {
          invoiceNumber: invoice?.invoiceNumber,
        }),
        <AISidebar
          steps={steps}
          stepIndex={stepIndex}
          isLoading={isAnalyzing}
          analysis={analysis}
        />
      )
    }
  }, [analysis, isAnalyzing, showDrawer, t])

  const onAnalyze = useCallback(async () => {
    if (!invoice) {
      return
    }

    setStepIndex(0)
    setAnalysis(undefined)
    await analyzeInvoice(invoice!.id)
  }, [invoice, analyzeInvoice, setStepIndex, setAnalysis])

  const steps = useMemo((): string[] => {
    return stepLabels.map((label: string) => t(label))
  }, [t])

  return (
    <Checked right="vendor:read">
      <Page>
        <Header
          subtitle={t('invoice.subtitle', {
            vendor: invoice?.vendor?.name,
          })}
          actions={
            <div className="flex flex-row space-x-2">
              <Button
                variant="neutral"
                className="space-x-1"
                onClick={onDownload}
              >
                <DocumentTextIcon className="h-4 w-4" />
                <span>{t('invoice.download')}</span>
              </Button>
              {invoice?.document?.id && (
                <Button
                  variant="neutral"
                  className="space-x-1"
                  onClick={() => processInvoice(invoice!.document!.id)}
                  disabled={isProcessing}
                >
                  {isProcessing ? (
                    <Spinner size="sm" />
                  ) : (
                    <ArrowPathIcon className="h-4 w-4" />
                  )}
                  <span>{t('invoice.process.action')}</span>
                </Button>
              )}
              <Button
                variant="negative"
                className="space-x-1"
                onClick={() => deleteInvoice(invoice!.id)}
                disabled={isDeleting}
              >
                <TrashIcon className="h-4 w-4" />
                <span>{t('invoice.delete.action')}</span>
              </Button>
              <Button
                variant="primary"
                className="space-x-1"
                onClick={onAnalyze}
                disabled={isAnalyzing}
              >
                <SparklesIcon className="h-4 w-4" />
                <span>{t('invoice.analyze')}</span>
              </Button>
            </div>
          }
        >
          {t('invoice.title', {
            invoiceNumber: invoice?.invoiceNumber,
          })}
        </Header>
        {!isLoading && invoice && (
          <Section>
            <Card>
              <div className="flex-1 px-4">
                <div className="mt-6">
                  <FieldList invoice={invoice} />
                </div>
                <div className="mt-16">
                  <Lines invoice={invoice} />
                </div>
              </div>
            </Card>
          </Section>
        )}
      </Page>
    </Checked>
  )
}

export default Invoice
