import { useMutation } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useNumberOptions from '../../hooks/useNumberOptions'
import { createAgreement, deleteAgreement } from '../../loaders/vendors'
import { Vendor, VendorAgreement } from '../../models'
import EditVendorAgreement from '../../routes/EditVendorAgreement'
import { formatDate } from '../../utils/format'
import { formatNumber } from '../../utils/format'
import DocumentList, {
  DocumentCard,
  UploadCard,
} from '../DocumentList/DocumentList'
import { useModal } from '../core/Modal/ModalProvider'

interface AgreementListProps {
  vendor: Vendor
  onChange?: () => void
}

const AgreementList = ({ vendor, onChange }: AgreementListProps) => {
  const { t } = useTranslation(['dashboard'])
  const { showModal, hideModal } = useModal()

  const numberOptions = useNumberOptions({
    isCurrency: true,
  })

  const [agreements, setAgreements] = useState(vendor.agreements)

  useEffect(() => {
    if (vendor.agreements) {
      setAgreements(vendor.agreements)
    }
  }, [vendor])

  const { mutate: createMutation } = useMutation({
    mutationFn: ({ name, location }: { name: string; location: string }) =>
      createAgreement(vendor.id, name, location),
    onSuccess: (agreement) => {
      setAgreements([...agreements, agreement])
      onChange?.()
    },
  })

  const { mutate: deleteMutation } = useMutation({
    mutationFn: (agreement: VendorAgreement) => deleteAgreement(agreement.id),
    onSuccess: (_, agreement) => {
      const newAgreements = agreements.filter((a) => a.id !== agreement.id)
      setAgreements(newAgreements)
      onChange?.()
    },
  })

  const onEdit = (agreement: VendorAgreement) => {
    showModal(
      <EditVendorAgreement
        agreement={agreement}
        onSaved={() => {
          hideModal()
          onChange?.()
        }}
        onCanceled={hideModal}
      />,
      { title: agreement.document?.name }
    )
  }

  return (
    <>
      <DocumentList>
        {agreements.map((agreement) => (
          <DocumentCard
            key={agreement.id}
            document={agreement.document!}
            leftText={`${formatDate(agreement.startDate, { format: 'L' })} - ${formatDate(agreement.endDate, { format: 'L' })}`}
            rightText={`${formatNumber(agreement.recurringCost, numberOptions)}/${t(`recurringCostFrequency.${agreement.recurringCostFrequency}`)}`}
            onEdit={() => onEdit(agreement)}
            onDelete={() => deleteMutation(agreement)}
            onUpdate={onChange}
          />
        ))}
        <UploadCard
          id="agreement-upload"
          onUpload={(name, location) => createMutation({ name, location })}
        />
      </DocumentList>
    </>
  )
}

export default AgreementList
