import {
  Popover as HeadlessPopover,
  PopoverButton,
  PopoverPanel,
} from '@headlessui/react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { usePopper } from 'react-popper'

export const PopoverSections = ({ children }) => (
  <div className="divide-y divide-gray-100">{children}</div>
)

PopoverSections.propTypes = {
  children: PropTypes.node.isRequired,
}

export const PopoverSection = ({ children }) => (
  <div className="py-6" role="none">
    {children}
  </div>
)

PopoverSection.propTypes = {
  children: PropTypes.node.isRequired,
}

export default function Popover({ text, icon, badgeCount, children }) {
  const [referenceElement, setReferenceElement] = useState()
  const [popperElement, setPopperElement] = useState()
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
  })

  return (
    <HeadlessPopover>
      <PopoverButton
        ref={setReferenceElement}
        className="inline-flex w-full h-10 justify-center items-center gap-x-1.5 rounded-full bg-gray-200 px-3 text-sm font-medium text-gray-900 hover:bg-gray-300 focus:outline-none"
      >
        {icon}
        <span>{text}</span>
        {badgeCount > 0 && (
          <span className="inline-flex items-center rounded-full bg-white px-2.5 py-1 text-xs font-medium text-gray-900">
            {badgeCount}
          </span>
        )}
      </PopoverButton>
      <PopoverPanel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className="z-10 mt-2 flex"
      >
        <div className="flex-auto rounded-lg bg-white text-sm px-6 leading-6 shadow-lg ring-1 ring-gray-900/5">
          {children}
        </div>
      </PopoverPanel>
    </HeadlessPopover>
  )
}

Popover.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
  badgeCount: PropTypes.number,
}
