import {
  ArrowDownTrayIcon,
  PencilIcon,
  SparklesIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import { getDownloadUrl, processDocument } from '../../loaders/documents'
import { Document } from '../../models'

const ActionButton = ({
  icon,
  onClick,
}: {
  icon: React.ReactNode
  onClick: () => void
}) => {
  return (
    <button
      onClick={(event) => {
        event.preventDefault()
        onClick()
      }}
      className="p-1 hover:bg-gray-100 rounded-sm"
    >
      {icon}
    </button>
  )
}

interface DocumentCardProps {
  document: Document
  leftText: string
  rightText: string
  onEdit?: () => void
  onDelete?: () => void
  onUpdate?: () => void
}

export const DocumentCard = ({
  document,
  leftText,
  rightText,
  onEdit,
  onDelete,
  onUpdate,
}: DocumentCardProps) => {
  const { t } = useTranslation(['dashboard'])
  const queryClient = useQueryClient()

  const [detectionStatus, setDetectionStatus] = useState<
    Document['detectionStatus']
  >(document.detectionStatus)

  const { mutate: processMutation } = useMutation(
    async () => {
      setDetectionStatus('processing')
      await processDocument(document.id)
    },
    {
      onSuccess: () => {
        setDetectionStatus('processed')
        onUpdate?.()
        queryClient.invalidateQueries({ queryKey: ['sidebar-item-count'] })
        toast.success(t('document.process.notification.success'))
      },
      onError: (error: unknown) => {
        setDetectionStatus('failed')
        toast.error(
          t('document.process.notification.error', {
            error: (error as Error).toString(),
          })
        )
      },
    }
  )

  const onDownload = useCallback(async () => {
    if (document) {
      const { url } = await getDownloadUrl(document.id)
      window.open(url, '_blank')
    }
  }, [document])

  return (
    <div
      key={document.id}
      className="overflow-hidden rounded-lg shadow-sm bg-white ring-1 ring-gray-200 divide-y divider-gray-100"
    >
      <div className="flex items-center justify-between px-2 py-2 sm:px-3">
        <span className="text-sm font-medium text-gray-700">
          {document?.name}
        </span>
        <div className="flex items-center gap-2">
          <ActionButton
            icon={
              <SparklesIcon
                aria-hidden="true"
                className="h-4 w-4 text-gray-500"
              />
            }
            onClick={processMutation}
          />
          {onEdit && (
            <ActionButton
              icon={
                <PencilIcon
                  aria-hidden="true"
                  className="h-4 w-4 text-gray-500"
                />
              }
              onClick={onEdit}
            />
          )}
          <ActionButton
            icon={
              <ArrowDownTrayIcon
                aria-hidden="true"
                className="h-4 w-4 text-gray-500"
              />
            }
            onClick={onDownload}
          />
          {onDelete && (
            <ActionButton
              icon={
                <TrashIcon
                  aria-hidden="true"
                  className="h-4 w-4 text-gray-500"
                />
              }
              onClick={onDelete}
            />
          )}
        </div>
      </div>
      <div className="text-sm text-gray-500 bg-gray-50 px-2 py-2 sm:p-3">
        {detectionStatus !== 'processed' ? (
          <div className="flex items-center justify-end">
            <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
              {t(`document.status.${detectionStatus}`)}
            </span>
          </div>
        ) : (
          <div className="flex items-center justify-between">
            <span>{leftText}</span>
            <span>{rightText}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default DocumentCard
