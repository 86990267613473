import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useQuery } from '@tanstack/react-query'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { NavLink, useMatch } from 'react-router-dom'

import useAuth from '../../../hooks/useAuth'
import { NavGroupItem, NavItem } from '../navigation'

interface SidebarItemProps {
  item: NavItem
}

export const SidebarItem = ({ item }: SidebarItemProps) => {
  const current = useMatch(item.href)
  const auth = useAuth()
  const { t } = useTranslation()

  if (item.right && !auth.rights.includes(item.right)) {
    return null
  }

  const { data: count } = useQuery({
    queryKey: ['sidebar-item-count', item.label],
    queryFn: item.countLoader,
    enabled: !!item.countLoader,
    staleTime: 6 * 1000,
  })

  return (
    <NavLink
      to={item.href}
      className={classNames(
        current
          ? 'bg-gray-50 text-brand-700'
          : 'hover:bg-gray-50 text-gray-700',
        'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 transition ease-in-out active:scale-95'
      )}
    >
      {item.icon && (
        <item.icon
          aria-hidden="true"
          className={classNames(
            current ? 'text-brand-700' : 'text-gray-400',
            'h-6 w-6 shrink-0'
          )}
        />
      )}
      {t(item.label)}
      {count ? (
        <span
          aria-hidden="true"
          className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs/5 font-medium text-gray-600 ring-1 ring-inset ring-gray-200"
        >
          {count}
        </span>
      ) : null}
    </NavLink>
  )
}

interface SidebarGroupSubItemProps {
  item: NavItem
}

export const SidebarGroupSubItem = ({ item }: SidebarGroupSubItemProps) => {
  const current = useMatch(item.href)
  const auth = useAuth()
  const { t } = useTranslation()

  if (item.right && !auth.rights.includes(item.right)) {
    return null
  }

  return (
    <li key={item.label}>
      <NavLink
        to={item.href}
        className={classNames(
          current
            ? 'bg-gray-50 text-brand-700'
            : 'hover:bg-gray-50 text-gray-700',
          'block rounded-md py-2 pl-9 pr-2 text-sm leading-6 transition ease-in-out active:scale-95'
        )}
      >
        {t(item.label)}
      </NavLink>
    </li>
  )
}

interface SidebarGroupItemProps {
  item: NavGroupItem
}

export const SidebarGroupItem = ({ item }: SidebarGroupItemProps) => {
  const current = useMatch(item.scope)
  const auth = useAuth()
  const { t } = useTranslation()

  // only display children with right to access
  const children = item.children.filter(
    (child) => !child.right || auth.rights.includes(child.right)
  )

  if (children.length === 0) {
    return null
  }

  return (
    <Disclosure as="div">
      <DisclosureButton
        className={classNames(
          current
            ? 'bg-gray-50 text-brand-700'
            : 'hover:bg-gray-50 text-gray-700',
          'group flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 transition ease-in-out active:scale-95'
        )}
      >
        {item.icon && (
          <item.icon
            aria-hidden="true"
            className="h-6 w-6 shrink-0 text-gray-400"
          />
        )}
        {t(item.label)}
        <ChevronRightIcon
          aria-hidden="true"
          className="ml-auto h-5 w-5 shrink-0 text-gray-400 group-data-[open]:rotate-90 group-data-[open]:text-gray-500"
        />
      </DisclosureButton>
      <DisclosurePanel as="ul" className="mt-1 px-2">
        {item.children.map((subItem) => (
          <SidebarGroupSubItem key={subItem.label} item={subItem} />
        ))}
      </DisclosurePanel>
    </Disclosure>
  )
}
