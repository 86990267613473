import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { GridCard } from '../components/Card/Card'
import Checked from '../components/Checked'
import { Header, Page, Section } from '../components/Page/Page'
import PaginationNav from '../components/PaginationNav/PaginationNav'
import Table from '../components/Table/Table'
import { TenantDetail } from '../components/TenantDetail/TenantDetail'
import { useDrawer } from '../components/core/Drawer/DrawerProvider'
import FormattedNumber from '../components/core/FormattedNumber/FormattedNumber'
import { getTenants } from '../loaders/tenants'
import { Tenant } from '../models'

const formatDate = (date: string) => (date ? moment(date).format('LL') : '-')

const Tenants = () => {
  const { t } = useTranslation(['dashboard'])
  const { showDrawer } = useDrawer()

  const [page, setPage] = useState(1)

  const { data, isLoading } = useQuery({
    queryKey: ['tenants', page],
    queryFn: () => getTenants({ page }),
  })

  const tenants = useMemo(() => data?.data ?? [], [data])
  const pagination = useMemo(() => data?.pagination, [data])

  const showTenant = useCallback(
    (tenant: Tenant) => {
      showDrawer(tenant.name, <TenantDetail tenant={tenant} />)
    },
    [showDrawer]
  )

  return (
    <Checked right="tenant:read">
      <Page>
        <Header subtitle={t('tenants.subtitle') ?? ''}>
          {t('tenants.title')}
        </Header>
        <Section>
          <GridCard>
            <Table
              isLoading={isLoading}
              headerCells={[
                t('tenants.column.name'),
                t('tenants.column.building'),
                t('tenants.column.startDate'),
                t('tenants.column.rentAmount'),
              ]}
              rows={tenants.map((tenant) => ({
                data: tenant,
                cells: [
                  <span key="name" className="font-medium">
                    {tenant.name}
                  </span>,
                  tenant.building.buildingReference,
                  formatDate(tenant.agreements[0]?.startDate),
                  <FormattedNumber
                    key="rentAmount"
                    value={tenant.agreements[0]?.rentAmount}
                  />,
                ],
              }))}
              onRowClick={(tenant) => showTenant(tenant)}
            />
            {pagination && pagination.pageCount > 1 && (
              <PaginationNav
                pagination={pagination}
                onPrevious={() => setPage(pagination?.previousPage ?? 1)}
                onNext={() => setPage(pagination?.nextPage ?? 1)}
              />
            )}
          </GridCard>
        </Section>
      </Page>
    </Checked>
  )
}

export default Tenants
