import { CheckCircleIcon } from '@heroicons/react/24/outline'

interface ProgressStepsProps {
  currentStepIndex: number
  steps: string[]
}

const getStatus = (index: number, currentStepIndex: number) => {
  if (index < currentStepIndex) return 'completed'
  if (index === currentStepIndex) return 'current'
  return 'waiting'
}

const AnimatedSteps = ({ currentStepIndex, steps }: ProgressStepsProps) => {
  return (
    <div>
      <nav aria-label="Progress" className="flex justify-center">
        <ol role="list" className="space-y-6">
          {steps.map((step, index) => {
            const status = getStatus(index, currentStepIndex)

            return (
              <li key={step}>
                {status === 'completed' ? (
                  <span className="flex items-start">
                    <span className="relative flex h-5 w-5 shrink-0 items-center justify-center animate-fadeInPop">
                      <CheckCircleIcon
                        aria-hidden="true"
                        className="h-5 w-5 text-gray-500"
                      />
                    </span>
                    <span className="ml-3 text-sm font-medium text-gray-500">
                      {step}
                    </span>
                  </span>
                ) : status === 'current' ? (
                  <span className="flex items-start">
                    <span
                      aria-hidden="true"
                      className="relative flex h-5 w-5 shrink-0 items-center justify-center"
                    >
                      <span className="absolute animate-ping inline-flex h-4 w-4 rounded-full bg-brand-400 opacity-75" />
                      <span className="relative inline-flex h-2 w-2 rounded-full bg-brand-500" />
                    </span>
                    <span className="ml-3 text-sm font-medium text-brand-600">
                      {step}
                    </span>
                  </span>
                ) : (
                  <div className="flex items-start">
                    <div
                      aria-hidden="true"
                      className="relative flex h-5 w-5 shrink-0 items-center justify-center"
                    >
                      <div className="h-2 w-2 rounded-full bg-gray-300" />
                    </div>
                    <p className="ml-3 text-sm font-medium text-gray-300">
                      {step}
                    </p>
                  </div>
                )}
              </li>
            )
          })}
        </ol>
      </nav>
    </div>
  )
}

export default AnimatedSteps
